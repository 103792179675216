import React from 'react';
import '../css/ContactUs.css'; // Ensure this path is correct
import banner from '../image/banner.jpg';
import { Helmet } from 'react-helmet';

const ContactUs = () => {
    return (
        <div>
            <Helmet>
                <title>Contact Us | Divvya Jyoti</title>
                <meta name="description" content="Divvya Jyoti offers expert astrology consultations and spiritual guidance. Learn more about our services, team, and unique mobile app for connecting with renowned astrologers." />
                <meta name="keywords" content="Divvya Jyoti, astrology consulting, spiritual guidance, Vedic astrology, Western astrology, Chinese astrology, mobile app, puja services, horoscopes" />
                <meta property="og:title" content="About Us | Divvya Jyoti" />
                <meta property="og:description" content="Discover how Divvya Jyoti connects seekers with profound wisdom through expert astrology consultations and spiritual guidance. Learn more about our services and unique mobile app." />
                <meta property="og:image" content="/path-to-image.jpg" /> {/* Adjust this path to your image */}
                <meta property="og:url" content="https://yourwebsite.com/about-us" /> {/* Update with your actual URL */}
            </Helmet>  

            {/* Banner Image */}
            <img className="image" src={banner} alt="Banner" />

            {/* Contact Form */}
            <div className="form">
                <h1>Get in Touch</h1>
                <h3>Feel free to reach out to us anytime if you have any questions</h3>
                <p>Email us: <a href="mailto:support@divvyajyoti.com">support@divvyajyoti.com</a></p>

                {/* Add Account Deletion Info */}
                <div className="account-deletion">
                    <h3>Account Deletion</h3>
                    <p>If you'd like to delete your account or request the deletion of your data, please visit our <a href="https://divvyajyoti.com/PRIVACY-POLICY.html" target="_blank" rel="noopener noreferrer">Privacy Policy</a> page for instructions or send a mail to above.</p>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
